import React, { useRef } from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"
import Button from "@elements/Button"
import { useStateContext } from "@context/stateContext"
import MovingElement from "@elements/MovingElement"

const Section = styled.section`
  ${tw`relative z-10 w-full  bg-blue pt-c120 md:pt-c96 2xl:pt-c120 pb-c40 md:pb-c32 2xl:pb-c40`}
  box-shadow: 0px 15px 30px 0px rgba(0,0,0,.75);
`
const HeadlineWrap = styled.div`
  ${tw`relative z-10 flex flex-col items-center text-4xl font-black 2xl:text-6xl font-ubuntu md:text-center px-offset md:px-offsetMd lg:px-offsetLg`}
`
const DescriptionGrid = styled.div`
  ${tw`grid grid-cols-none  pt-c120 md:pt-c96 2xl:pt-c120 md:grid-cols-2 px-offset md:px-0`}
`
const Description = styled.div`
  ${tw`relative z-10 pt-6 font-normal text-white  md:col-start-2`}
  & strong {
    ${tw`font-black`}
  }
`
const BigArrow = styled.div`
  ${tw`absolute top-0 right-0 transform rotate-180 translate-x-1/2 text-8xl lg:text-14xl font-firacode`}
`

const WpAcfContactModuleBlock = ({ moduleData, isHome }) => {
  const blockData = moduleData.acfContactBlock
  const sectionAttributes = moduleData.attributes
  const defaultId = `contact-section-${Math.floor(Math.random() * 100)}`

  const sectionRef = useRef()
  const { openContactModal } = useStateContext()
  const formClick = () => {
    openContactModal()
  }

  return (
    <div id="contact-wrapper" className="relative wrapper">
      <Section
        ref={sectionRef}
        id={`${sectionAttributes.anchor || defaultId}`}
        style={{
          backgroundColor: blockData.backgroundColor,
        }}
      >
        <BigArrow
          style={{
            color: blockData.symbolsColor,
          }}
        >
          {parse(`->>`)}
        </BigArrow>

        {isHome && (
          <div className="absolute z-10 flex flex-col items-center mx-auto floating-symbol -top-80 right-80">
            <MovingElement
              speed={0.05}
              direction={{
                negative: "",
                axis: "y",
              }}
              content={false}
              className="absolute left-0 right-0 mx-auto transition-all duration-300 ease-linear transform top-c80"
            >
              <span
                style={{
                  writingMode: "vertical-lr",
                  lineHeight: "1.6",
                }}
                className="absolute block mx-auto text-6xl font-black text-center transform scroll-element font-firacode whitespace-nowrap text-yellow top-c80"
              >
                {parse(`->>-`)}
              </span>
            </MovingElement>
          </div>
        )}
        <HeadlineWrap>
          <h2 className="relative font-bold headline-section font-ubuntu">
            <span
              style={{
                color: blockData.symbolsColor,
              }}
              className="absolute text-5xl transform -translate-x-full -translate-y-1/2 font-firacode top-1/2 -left-10"
            >
              {parse(`]|{}->`)}
            </span>
            <span
              style={{
                color: blockData.textColor,
              }}
              className="relative"
              data-start-symbol={blockData.startSymbol}
              data-end-symbol={blockData.endSymbol}
            >
              {parse(blockData.headline)}
            </span>
          </h2>

          <Button
            onClick={
              blockData.button.url.includes("kontakt") ? formClick : undefined
            }
            buttonText={blockData.button.title}
            buttonHref={
              blockData.button.url.includes("kontakt")
                ? "/"
                : blockData.button.url
            }
            buttonClasses="bg-yellow text-black mr-auto"
          />
        </HeadlineWrap>

        <DescriptionGrid>
          {blockData.description !== null && (
            <Description className="copy">
              {parse(blockData.description)}
            </Description>
          )}
        </DescriptionGrid>
      </Section>
    </div>
  )
}

WpAcfContactModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfContactModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfContactModuleBlock
